



.table-config{
    margin-top: 5%;
    display:flex;
    justify-content: space-between;
}
.table-config>input{
    padding: 0 11px;
    min-width: 40%;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
}
#add-new-obj{
    border: var(--violeta) 1px solid;
    font-weight: 5000;
    border-radius: 4px;
    cursor: pointer;
    background: var(--violeta);
    color: #fff;
    padding: 8px 25px;
    font-size: 0.9em;
    transition: .2s;
}
#add-new-obj:hover{
    background: transparent;
    color: var(--violeta);

}


#NewObj form>div{
    margin: 4% 0;
}
#NewObj label{
    font-size: 0.9em;
    color: #7b7b7b;
    display: block;
    font-weight: 400;
    margin-bottom: 1%;
}
#NewObj textarea{
    resize: none;
    overflow: auto;
    background: transparent;
    border: none;
    border-bottom: 1px solid #777777;
    width: 94%;
    background: #e7e7e7;
    border-radius: 5px;
    height: 9vh;
    padding: 1% 2%;
}
#NewObj input{
    background: transparent;
    border: none;
    border-bottom: 1px solid #777777;
    background: #e7e7e7;
    border-radius: 5px;
    padding: 8px 15px;

}
#NewObj select{
    padding: 13px 31px;
    border: none;
    background: #e7e7e7;
    color: #1c1c1c;
    cursor: pointer;
}
:root {
    --select-border: #777;
    --select-focus: blue;
    --select-arrow: var(--violeta);
  }
#NewObj select option{
    padding: 5px 0;
    display: block;
}
#NewObj>div>form>button{
    padding: 4px 10px;
    border: var(--violeta) 1px solid;
    background: #7f45c457;
    color: var(--violeta);
    font-weight: 5000;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.85em;
    margin-top: 5%;
}
#NewObj>div>form>div>button{
    padding: 4px 10px;
    border: var(--violeta) 1px solid;
    background: #7f45c457;
    color: var(--violeta);
    font-weight: 5000;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}
.nueva-rta-btn{
    margin: 0!important;
    width: 100%!important;
    opacity: 0.7;
}
.nueva-rta-btn:hover{
    opacity: 0.9;
}
#create-obj{
    background: var(--violeta)!important;
    color: #fff!important;
    padding: 8px 25px!important;
    font-size: 1em;
    border: var(--violeta) 1px solid;
    transition: .2s;
    /* border-radius: 5px;
    cursor: pointer; */
}
#create-obj:hover{
    background: transparent!important;
    color: var(--violeta)!important;

}
#rene{
    border: #777 1px solid!important;
    background: transparent!important;
    color: #777!important;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;

    padding: 8px 25px!important;

    margin-left: 3.5%;

    font-size: 1em;
    transition: .2s;
}
#rene:hover{
    background-color: #d6d6d6!important;
}


#ObjTable-view .table{
    margin-top: 5%;
}
#ObjTable-view .tr>div{
    height: 9vh;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    padding: 0 2%;
}
#ObjTable-view .tr>div.opened{
    height: auto!important;
    padding: 2%;
}
#ObjTable-view .tr:nth-child(odd){
    background-color: #e7e7e7;
}
#ObjTable-view .th{
    padding: 0 1%;
    font-size: 0.9em;
    text-align: left;
    font-weight: 600;
}
#ObjTable-view .td{
    padding: 0 1%;
    /* border-right: 1px solid #fff;
    height: 100%; */
}
#ObjTable-view .td p{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 0.8em;
}
.id-cell{
    width: 3%;
}
.obj-cell{
    width: 50%;
}
.cant-cell{
    width: 5%;
}
.th.rtas-cell{
    height: auto!important;
}
.rtas-cell{
    width: 81%!important;
    font-size: 0.9em!important;
    height: 100%!important;
    display: flex;
    justify-content: flex-start!important;
    align-items: flex-start!important;
    flex-direction: column;
    position: relative;
    margin-top: 20px;
    margin-left: 5%;
}
.rtas-cell p{
    margin-bottom: 4%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.respuestas-desplegadas:hover{
    font-weight: 600;
}
.rtas-cell p:before{
    content: '·';
    font-weight: bolder;
    margin-right: 10px;
}
.key-cell{
    width: 15%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    overflow: hidden;
    align-items: center;
}
.key-cell p{
    display: inline-block!important;
    width: fit-content!important;
    font-size: 0.65em!important;
    margin: 2px 2px;
    background-color: #d2b9f0;
    border-radius: 5px;
    padding: 2px;
}
.cat-cell{
    width: 8%;
}
.cant-cell p{
    text-align: center;
}
.arrowCerrada{
    transform: rotate(-90deg);
}
.arrowAbierta{
    transform: rotate(0deg);
}
.arrow-cont>img{
    width: 100%;
    position: absolute;
    transition: .2s;
    top: 38%;
}
.arrow-cont{
    width: 11px;
    height: 100%;
    position: relative;
    margin-right: 10px;
    margin-left: 20px;
    filter: invert(0.2);
}
.delete-btn{
    position: absolute;
    top: 50%;
    left: 100%;
    padding: 2%;
    cursor: pointer;
    transform: translateY(-50%);
    /* display: none; */
    font-size: 0.8em;
    font-weight: 600;
    opacity: 0;
}
#ObjTable-view .tr:hover .delete-btn{
    opacity: 0.7;
}
.delete-btn:hover{
    opacity: 1!important;
}
.row-opts{
    width: 16px;
    opacity: 0;
}
.tr:hover .row-opts{
    opacity: 0.8;
}
.row-opts:hover{
    opacity: 1!important;
}
.row-opts>img{
    width: 100%;
    filter: invert(0.2);
}
.agregar-rta{
    position: absolute;
    right: -5%;
    top: 30%;
    transform: translateY(-50%);
    
}

.agregar-rta>button{
    background: var(--violeta);
    border:none;
    color: white;
    font-size: 12px;
    padding: 3px 7px;
    cursor: pointer;
    transition: .2s;
    border-radius: 50px;
}
.agregar-rta>button:hover{
    opacity: 0.8
}



#exist-cont>div{

}
#alert-info{
    border-radius: 3px;
    border: 1px solid rgb(255 214 66);
    background: rgb(255 235 162);
    padding: 5px 10px;
    font-size: 0.8em;
    color: #1c1c1c;
    /* display: flex;
    justify-content: space-between; */
    cursor: pointer;
}
#alert-info>div{
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
#alert-info>div img{
    margin-right: 10px;
}
#alert-info i{
    font-size: 0.9em;

}
#alert-detail button{
    padding: 4px 10px;
    border: var(--violeta) 1px solid;
    background: #7f45c457;
    color: var(--violeta);
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
}
#alert-detail>div{
    border-radius: 3px;
    padding: 12px 10px;
    background-color: rgb(255, 245, 208);
    border-bottom: 1px solid rgb(255 214 66);
}
#alert-detail>div>p{
    font-size: 0.8em;
    color: #1c1c1c;
    font-weight: 600;
}
#alert-detail>div>ul{
    display: flex;
}
#alert-detail>div>ul>li{
    width: fit-content;
    font-size: 0.65em;
    margin: 2px 2px;
    border: 1px solid #8d6db5;
    background-color: #d2b9f0;
    border-radius: 5px;
    padding: 2px;
}