
.close-mod{
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
}

.ul-editable li{
    display: flex;
    align-items: center;
    margin: 2% 0;
}
.ul-editable li img{
    filter: invert(0.4);
    margin-left: 10px;
    width: 16px;
    cursor: pointer;
}