
#UsersTable-view .table{
    margin-top: 5%;
}
#UsersTable-view .tr>div{
    height: 9vh;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    padding: 0 2%;
}
#UsersTable-view .table-config>button{
    border: var(--violeta) 1px solid;
    border-radius: 4px;
    cursor: pointer;
    background: var(--violeta);
    color: #fff;
    padding: 8px 25px;
    font-size: 0.9em;
    transition: .2s;
    /* font-weight: 500; */
    /* letter-spacing: 2px; */
}
#UsersTable-view .tr:nth-child(odd){
    background-color: #e7e7e7;
}
#UsersTable-view .th{
    padding: 0 1%;
    font-size: 0.9em;
    text-align: left;
    font-weight: 600;
}

#UsersTable-view .td p{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 0.8em;
}
#UsersTable-view .td p{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 0.8em;
}


#UsersTable-view .sug-cell{
    width: 25%;
}
#UsersTable-view .id-cell{
    width: 3%;
}
#UsersTable-view .obj-cell{
    width: 50%;
}
#UsersTable-view .cant-cell{
    width: 5%;
    text-align: center;
    font-size: 0.8em;
}
#UsersTable-view .th.rtas-cell{
    height: auto!important;
}
#UsersTable-view .tipo-cell{
    width: 10%;
    font-size: 0.8em;
}
.user-name-cell{
    width: 25%;
}
.user-mail-cell{
    width: 40%;
}
.delete-cell{
    width: 10%;
    text-align: center;
}
.delete-user{
    font-size: 0.8em;
    color: #898989;
}
.tr:hover .delete-user{
    color: #585858;
}

.delete-user:hover{
    color:#2f2f2f;
    font-weight: 600
}
.save-user-change{
    padding: 8px 25px;
    border: var(--violeta) 1px solid;
    background: #7f45c457;
    color: var(--violeta);
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.85em;
    margin-top: 5%;
}
.discard-user-change{
    border: #777 1px solid!important;
    background: transparent!important;
    color: #777!important;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px 25px;
    margin-left: 3.5%;
    font-size: 0.85em;
    transition: .2s;
}
#UserModal>section{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.inputs-cont{
    width: 45%;
    display: inline-block;
    margin-bottom: 8%;
}
.inputs-cont label{
    font-size: 0.8em;
    display: block;
    color: #2f2f2f;

}
.inputs-cont input{
    background-color: #f0f0f0;
    border: none;
    border-bottom: 1px solid #585858;
    padding: 8px 12px;
    border-radius: 3px;
    width: 95%;

}