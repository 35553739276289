#Home-view{
    background-color: #f0f0f0;
    min-height: 100vh;
}

/* ?token=exp=1654889023~hmac=52d4d78e368634e9cab854437091d933 */

#top-bar{
    padding: 5% 0;
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: auto;
}
#buscador{
    width: 80%;
}
#buscador>div:first-child{
    margin: auto;
    width: 100%;
    /* padding: 0 40px; */
    background: white;
    box-shadow: 0px 6px 10px 0px #dbdbdb;
    position: relative;
    border-radius: 55px;

}
#buscador label{
    position: absolute;
    width: 90%;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
}
#buscador label img{
    filter: invert(0.5);
}
#buscador input{
    padding: 15px 15%;
    border: none;
    border-radius: 55px;
    width: 70%;
    position: relative;
    background: none;
}

#notifications{position:relative}
#notifications>div{
    border-radius: 55px;
    background: white;
    /* width: 50px; */
    height: 100%;
    width: 50px;
    /* padding-right: 143%; */
    position: relative;
    box-shadow: 0px 6px 10px 0px #dbdbdb;
    display: block;
    cursor: pointer;
}

#notifications>div img{
    position: absolute;
    width: 40%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: invert(0.4);
}
#notifications>div:hover img{
    filter: invert(0.2);
}
#resultados-cont{
    width: 60%;
    padding-bottom: 15%;
    margin: auto;
}
#resultados-cont>div{
    display: grid;
    grid-auto-columns: column;
    grid-template-columns: 45% 45%;
    grid-template-rows: 35vh;
    grid-gap: 7vh 7vh;
    gap: 20vh 10%;
}

.objecion-card{
    padding: 11%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 6px 10px 0px #dbdbdb;
    cursor: pointer;
    transition: .2s;
    position: relative;
    height: 100%;
    overflow: hidden;
}
.objecion-card:hover{
    box-shadow: 0px 6px 15px 3px #dbdbdb;
    transform: scale(1.05);
}
.objecion-card h5{
    font-size: 1em;
    margin-bottom: 20px;
    color: #272727
}
.objecion-card h3{
    font-size: 1.3em;
    color: var(--violeta);
    margin: 4% 0 5% 0;
    font-weight: 500;
    font-style: italic;
}
.objecion-card h4{
    font-size: 0.9em;
    color: #1f1f1f;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.objecion-card li{
    font-size: 0.8em;
    color: #1c1c1c;
    margin: 10px 0;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    position: relative;

}
.objecion-card li p{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
    -webkit-box-orient: vertical;

}
.objecion-card li:last-child{
    border-bottom: none
}
.rtas-option{
    position: absolute;
    width: 16px;
    right: 0%;
    top: 10%;
    transition: .2s;
    opacity: 0;
    transition: .2s;
}
.rta:hover .rtas-option{
    opacity: 0.6;
}
.rtas-option:hover{
    opacity: 1!important;
}
.rtas-option img{
    transition: .2s;
    width: 100%;
}
.mas-rtas{
    position: absolute;
    font-size: 0.8em;
    padding: 2px 8px;
    background-color: #b79bd9;
    color: var(--violeta);
    border-radius: 50px;
    right: 5%;
    bottom: 5%;
    font-weight: 600;
}


#no-search{
    text-align: center;
    margin: auto;
    width: 50%;
    transform: translateY(-100%);
    font-size: 2em;
    color: #b9b9b9;
}

#new-objecion{
    position: fixed;
    bottom: 3%;
    right: 2%
}
#new-objecion>div{
    border-radius: 100px;
    width: 64px;
    height: 64px;
    background-color: var(--violeta);
    position: relative;
    cursor: pointer;
}
#new-objecion>div span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: 500;
    font-size: 24px;
    transition: 0.6s;
}
#new-objecion>div:hover span{
    transform: translate(-50%, -50%) rotate(360deg);
}

#new-objecion>div aside{
    position: absolute;
    bottom: 120%;
    right: 0%;
    background-color: #f0f0f0;  
    border: 1px solid #ddd;
    box-shadow: 0px 0px 7px 1px #ddd;
    padding: 10px 14px;
    width: fit-content;
    min-width: 200%;
    border-radius: 5px;
    font-size: 0.9em;
    text-align: right;
}

.autor-cont{
    position: absolute;
    bottom: 4%;
    font-size: 0.65em;
    background: #ca9dff;
    border-radius: 13px;

}

.autor-cont>span:first-child{
    background: var(--violeta);
    padding: 4px 8px 4px 13px;
    border-radius: 13px;
    color: white;
}
.autor-cont>span:last-child{
    padding: 4px 13px 4px 8px;
    border-radius: 13px;
    color: var(--violeta);
    display: inline-block;
}

@media (max-width: 800px) {
#top-bar{
    width: 90%;
    position: fixed;
    left: 5%;
    bottom: 13%;
    z-index: 5;
}
#no-search {
    width: 90%;
    transform: translateY(-200%);
}
#new-objecion {
    top: 3%;
    right: 3%;
}
#resultados-cont{
    width: 90%;
    padding-bottom: 25vh;
}
#resultados-cont>div{
    padding-top: 12vh;
    gap: 0 4%;
    grid-template-rows: 30vh;
    grid-template-columns: 48% 48%;
}
.objecion-card{
    height: 78%;
}


}