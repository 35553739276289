.close-mod{
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
}
#objecion-data h4{
    font-size: 1.3em;
    color: var(--violeta);
    margin-bottom: 5%;
}
.label-rtas{
    font-size: 0.8em;
    color: #1c1c1c;
}
.info-rta{
    margin: 3% 0;
    padding: 3% 0;
    border-bottom: 1px solid #ddd;
    position:relative
}
.info-rta>div{
    position:relative

}
.info-rta:nth-child(2){
    /* margin-top: 0!important;
    padding-top: 0!important; */
}
.info-rta:last-child{
    border: none;
}
.info-rta span{
    display: block;
    font-size: 0.7em;
    color: #1c1c1c;
    margin-top: 4%;
}
.nombre-rta, .contenido-rta, .campo-variaciones textarea, .inputs-rta{
    background-color: #f0f0f0;
    padding: 8px 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: calc(100% - 28px);
    font-size: 0.9em;
    resize: none;
}
.inputs-rta{
    width: auto;
}
textarea.contenido-rta{
    height: 15vh;
}
.delete-rta{
    width: 14px;
    position: absolute!important;
    text-align: 50%;
    top: 20px;
    transform: translateY(-50%);
    right: 0;
    opacity: 0.7;
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 50px;
    padding: 2px 8px;
}
.delete-rta:hover{
    opacity: 1;
}
.delete-rta img{
    width: 100%;
}
#add-rta-btn{
    width: 100%;
    background-color: transparent;
    border: 1px dashed #a3a3a3;
    padding: 8px 0;
    border-radius: 5px;
    color: #3a3a3a;
    cursor: pointer;
    margin-bottom: 10%;
}
#add-rta-btn:hover{
    background-color: #d5d5d5;
}
#guardar-cambios-btn{
    background-color: var(--violeta);
    border-radius: 5px;
    border: var(--violeta);
    padding: 8px 22px;
    color: white;
    cursor: pointer;
    margin-right: 25px;

}
#descartar-cambios-btn{
    border: 1px solid #ddd;
    background: white;
    padding: 8px 22px;
    border-radius: 5px;
    cursor: pointer;
}
.campo-variaciones, .variac-tit{
    padding-left: 5%;
    position: relative;
}
.variac-tit{
    margin-bottom: 3%;
}
.campo-variaciones textarea{
    font-size: 0.8em;
}

.close-variac{
    position: absolute;
    right: -25px;
    width: 15px;
    top: 40%;
    transform: translateY(-50%);

}

.agregar-variante{
    position: absolute;
    text-align: start;
    top: 40px;
    right: -39px;
    border: 1px solid var(--violeta);
    color: var(--violeta);
    border-radius: 50px;
    padding: 0px 6px;
    cursor: pointer;
    font-size: 15px;
}
.agregar-variante:before{
    content: "Agregar variante";
    font-size: 0.8em;
    color: white;
    position:absolute;
    right: 115%;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--violeta);
    border-radius: 10px;
    padding: 5px 10px;
    display: none;
}
.agregar-variante:hover:before{
    display: block;
}