#Login-view{
    width: 100%;
    height: 100vh;
    background: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
}
#Login-view h4{
    font-size: 2em;
}
#Login-view label{
    display: block;
}
#Login-view input{
    display: block;
    margin: 7% auto;
    padding: 8px 24px;
    border-radius: 5px;
    border: 1px solid #ddd;
    background-attachment: #f0f0f0;
}
#Login-view button{
    
    padding: 10px 25px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
    margin: auto;
    display: block;
}

#Login-view button:hover{
    opacity: 0.8;
}