/* #ModalNewObj-view{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0,0,0,0.15);
    backdrop-filter: blur(5px);
    z-index: 99;
}

#ModalNewObj-view>div{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 60px;
    padding-bottom: 30px;
    background-color: #fff;
    border-radius: 10px;
    max-height: 70vh;
    overflow: auto;

} */
#ModalNewObj-view label{
    font-size: 0.8em;
    color: darkgray;
    display: block;
    margin-top: 5%;
}
#ModalNewObj-view textarea, #ModalNewObj-view input{
    /* margin: 3% 0; */
    width: 94%;
    padding: 3%;
    background-color: #fafafa;
    border: 1px solid #ddd;
    resize: none;
    border-radius: 5px;
}

#ModalNewObj-view input{
    padding: 1.5%;
    width: 97%;
    

}

#ModalNewObj-view>div>div{
    position: relative;
}


#new-rta{
    background-color: transparent;
    border-radius: 5px;
    padding: 8px 16px;
    color: slategray;
    border:1px dashed slategray;
    cursor: pointer;
    display: block;
    margin: 3% 0;
    width: 100%;
}
#new-rta:hover{
    background-color: #f0f0f0;
}

#add-rta{
    position: relative;
    width: 100%;
    /* height: 100%; */
    background: #fff;
    display: flex;
    justify-content: center;
    
}
#add-rta>div{ width: 100%; }
#add-rta textarea{
    height: 55px;
    color: slategray;
    margin: 0;
}
.deleteRta{
    width: 16px;
    position: absolute;
    text-align: start;
    top: 60%;
    left: 102%;
    filter: invert(0.3);
    cursor: pointer;
}
.deleteRta img{
    width: 100%;
}

.deleteRta:hover{
    filter: invert(0);
}
.cancel-newrta{
    display: block;
    margin: 0;
    background: transparent;
    border: 1px solid #ddd;
    color: slategray;
    padding: 8px 25px;
    border-radius: 5px;
    cursor: pointer;
}
.cancel-newrta:hover{
    background: #f0f0f0;
}
.send-newrta{
    color: var(--violeta);
    background: #7f45c42e;
    border: var(--violeta) 1px solid;
    border-radius: 5px;
    padding: 8px 15px;
    display: block;
    margin: 0;
    transition: .2s;
}
.send-newrta:hover{
    background: #7e45c469;
    font-weight: 500;
    cursor: pointer;
}
#new-obj-btns button{
    width: 45%;
}
#new-obj-btns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10%;
}

#ModalNewObj-view .close-mod{
    top: -36px;
    right: -36px;
}
@media (max-width: 800px){
#ModalNewObj-view .close-mod{
    right: -13px;
    top: -13px;
}

#ModalNewObj-view>div{
    padding: 25px!important;
    width: calc(90% - 50px);
}


}
    