#OpenCard-view{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0,0,0,0.15);
    backdrop-filter: blur(5px);
    z-index: 9;
}

#OpenCard-view>div{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 60px;
    padding-bottom: 30px;
    background-color: #fff;
    border-radius: 10px;
    max-height: 70vh;
    overflow-y : auto;
    overflow-x : hidden;
    width: 50vw;
}
#OpenCard-view>div>div{
    position: relative;
}
#OpenCard-view h3{
    font-size: 1.3em;
    color: var(--violeta);
    margin: 4% 0;
    font-weight: 500;
    font-style: italic;
}
#OpenCard-view ul{
    /* padding: 5%; */
    position: relative;
}
#OpenCard-view ul li{
    padding: 5% 0;
    color: rgb(91, 91, 91);
    position: relative;
    border-bottom: 1px solid #ddd;
}
#OpenCard-view ul li:last-child{
    border-bottom: none;
}
#OpenCard-view ul li>div{
    position: absolute;
    width: 16px;
    right: -5%;
    top: 20%;
    opacity: 0;
    cursor: pointer;
}
#OpenCard-view ul li:hover div{
    opacity: 0.5;
} 
#OpenCard-view ul li>div:hover{
    opacity: 1;
}
#OpenCard-view ul li>div img{
    width: 100%;
}
.rta-rta textarea{
    background-color: #f0f0f0;
    padding: 8px 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: calc(100% - 28px);
    font-size: 0.9em;
    resize: none;
    height: 30vh;
}

#OpenCard-view #close-mod{
    width: 24px;
    position: absolute;
    top: -40px;
    right: -40px;
    cursor: pointer;
    opacity: 0.7;

}
#close-mod:hover{
    opacity: 0.9;
}

#close-mod img{
    width: 100%;
}
#new-rta{
    background-color: transparent;
    border-radius: 5px;
    padding: 8px 16px;
    color: slategray;
    border:1px dashed slategray;
    cursor: pointer;
    display: block;
    margin: 5% auto;
}
#new-rta:hover{
    background-color: #f0f0f0;
}

#add-rta{
    /* position: absolute; */
    top: 0%;
    left: 0;
    width: 100%;
    /* height: 100%; */
    background: #fff;
    display: flex;
    justify-content: center;
}
#add-rta>div{ width: 100%; }
#add-rta textarea, #OpenCard-view #add-rta input{
    display: block;
    width: 70%;
    padding: 3%;
    resize: none;
    border: 1px solid #ddd;
    background: #fafafa;
    border-radius: 7px;
}
#OpenCard-view #add-rta textarea{
    margin: auto;
    margin-bottom: 5%;
    height: 90px;
    color: slategray;

}
#OpenCard-view #add-rta input{
    margin: 10% auto 5%;
    color: slategray;
}
#OpenCard-view #add-rta>div button{
    margin: 3% auto;
    display: block;    
}
.cancel-newrta{
    display: block;
    margin: 5% auto;
    background: transparent;
    border: 1px solid #ddd;
    color: slategray;
    padding: 8px 25px;
    border-radius: 5px;
    cursor: pointer;
    width: 55%;
}
.cancel-newrta:hover{
    background: #f0f0f0;
}
.send-newrta{
    color: var(--violeta);
    background: #7f45c42e;
    border: var(--violeta) 1px solid;
    border-radius: 5px;
    padding: 8px 15px;
    margin: 5% auto;
    display: block;
    transition: .2s;
    width: 55%;
}
.send-newrta:hover{
    background: #7e45c469;
    font-weight: 500;
    cursor: pointer;
}

@media (max-width: 800px){
#close-mod{
    right: -13px;
    top: -13px;
}

#OpenCard-view>div{
    padding: 25px!important;
    width: calc(90% - 50px);
}
    

}

