@import url(https://fonts.googleapis.com/css2?family=Catamaran:wght@100;200;300;400;500;600;700;800;900&family=Exo:wght@100;300&family=Georama:wght@200;300;400;600;900&family=Poppins:wght@200;400;500;700&display=swap);
#Home-view{
    background-color: #f0f0f0;
    min-height: 100vh;
}

/* ?token=exp=1654889023~hmac=52d4d78e368634e9cab854437091d933 */

#top-bar{
    padding: 5% 0;
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: auto;
}
#buscador{
    width: 80%;
}
#buscador>div:first-child{
    margin: auto;
    width: 100%;
    /* padding: 0 40px; */
    background: white;
    box-shadow: 0px 6px 10px 0px #dbdbdb;
    position: relative;
    border-radius: 55px;

}
#buscador label{
    position: absolute;
    width: 90%;
    left: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
}
#buscador label img{
    -webkit-filter: invert(0.5);
            filter: invert(0.5);
}
#buscador input{
    padding: 15px 15%;
    border: none;
    border-radius: 55px;
    width: 70%;
    position: relative;
    background: none;
}

#notifications{position:relative}
#notifications>div{
    border-radius: 55px;
    background: white;
    /* width: 50px; */
    height: 100%;
    width: 50px;
    /* padding-right: 143%; */
    position: relative;
    box-shadow: 0px 6px 10px 0px #dbdbdb;
    display: block;
    cursor: pointer;
}

#notifications>div img{
    position: absolute;
    width: 40%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-filter: invert(0.4);
            filter: invert(0.4);
}
#notifications>div:hover img{
    -webkit-filter: invert(0.2);
            filter: invert(0.2);
}
#resultados-cont{
    width: 60%;
    padding-bottom: 15%;
    margin: auto;
}
#resultados-cont>div{
    display: grid;
    grid-auto-columns: column;
    grid-template-columns: 45% 45%;
    grid-template-rows: 35vh;
    grid-gap: 7vh 7vh;
    grid-gap: 20vh 10%;
    gap: 20vh 10%;
}

.objecion-card{
    padding: 11%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 6px 10px 0px #dbdbdb;
    cursor: pointer;
    transition: .2s;
    position: relative;
    height: 100%;
    overflow: hidden;
}
.objecion-card:hover{
    box-shadow: 0px 6px 15px 3px #dbdbdb;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}
.objecion-card h5{
    font-size: 1em;
    margin-bottom: 20px;
    color: #272727
}
.objecion-card h3{
    font-size: 1.3em;
    color: var(--violeta);
    margin: 4% 0 5% 0;
    font-weight: 500;
    font-style: italic;
}
.objecion-card h4{
    font-size: 0.9em;
    color: #1f1f1f;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.objecion-card li{
    font-size: 0.8em;
    color: #1c1c1c;
    margin: 10px 0;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    position: relative;

}
.objecion-card li p{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
    -webkit-box-orient: vertical;

}
.objecion-card li:last-child{
    border-bottom: none
}
.rtas-option{
    position: absolute;
    width: 16px;
    right: 0%;
    top: 10%;
    transition: .2s;
    opacity: 0;
    transition: .2s;
}
.rta:hover .rtas-option{
    opacity: 0.6;
}
.rtas-option:hover{
    opacity: 1!important;
}
.rtas-option img{
    transition: .2s;
    width: 100%;
}
.mas-rtas{
    position: absolute;
    font-size: 0.8em;
    padding: 2px 8px;
    background-color: #b79bd9;
    color: var(--violeta);
    border-radius: 50px;
    right: 5%;
    bottom: 5%;
    font-weight: 600;
}


#no-search{
    text-align: center;
    margin: auto;
    width: 50%;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    font-size: 2em;
    color: #b9b9b9;
}

#new-objecion{
    position: fixed;
    bottom: 3%;
    right: 2%
}
#new-objecion>div{
    border-radius: 100px;
    width: 64px;
    height: 64px;
    background-color: var(--violeta);
    position: relative;
    cursor: pointer;
}
#new-objecion>div span{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: white;
    font-weight: 500;
    font-size: 24px;
    transition: 0.6s;
}
#new-objecion>div:hover span{
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
}

#new-objecion>div aside{
    position: absolute;
    bottom: 120%;
    right: 0%;
    background-color: #f0f0f0;  
    border: 1px solid #ddd;
    box-shadow: 0px 0px 7px 1px #ddd;
    padding: 10px 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 200%;
    border-radius: 5px;
    font-size: 0.9em;
    text-align: right;
}

.autor-cont{
    position: absolute;
    bottom: 4%;
    font-size: 0.65em;
    background: #ca9dff;
    border-radius: 13px;

}

.autor-cont>span:first-child{
    background: var(--violeta);
    padding: 4px 8px 4px 13px;
    border-radius: 13px;
    color: white;
}
.autor-cont>span:last-child{
    padding: 4px 13px 4px 8px;
    border-radius: 13px;
    color: var(--violeta);
    display: inline-block;
}

@media (max-width: 800px) {
#top-bar{
    width: 90%;
    position: fixed;
    left: 5%;
    bottom: 13%;
    z-index: 5;
}
#no-search {
    width: 90%;
    -webkit-transform: translateY(-200%);
            transform: translateY(-200%);
}
#new-objecion {
    top: 3%;
    right: 3%;
}
#resultados-cont{
    width: 90%;
    padding-bottom: 25vh;
}
#resultados-cont>div{
    padding-top: 12vh;
    grid-gap: 0 4%;
    gap: 0 4%;
    grid-template-rows: 30vh;
    grid-template-columns: 48% 48%;
}
.objecion-card{
    height: 78%;
}


}
#OpenCard-view{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0,0,0,0.15);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    z-index: 9;
}

#OpenCard-view>div{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 60px;
    padding-bottom: 30px;
    background-color: #fff;
    border-radius: 10px;
    max-height: 70vh;
    overflow-y : auto;
    overflow-x : hidden;
    width: 50vw;
}
#OpenCard-view>div>div{
    position: relative;
}
#OpenCard-view h3{
    font-size: 1.3em;
    color: var(--violeta);
    margin: 4% 0;
    font-weight: 500;
    font-style: italic;
}
#OpenCard-view ul{
    /* padding: 5%; */
    position: relative;
}
#OpenCard-view ul li{
    padding: 5% 0;
    color: rgb(91, 91, 91);
    position: relative;
    border-bottom: 1px solid #ddd;
}
#OpenCard-view ul li:last-child{
    border-bottom: none;
}
#OpenCard-view ul li>div{
    position: absolute;
    width: 16px;
    right: -5%;
    top: 20%;
    opacity: 0;
    cursor: pointer;
}
#OpenCard-view ul li:hover div{
    opacity: 0.5;
} 
#OpenCard-view ul li>div:hover{
    opacity: 1;
}
#OpenCard-view ul li>div img{
    width: 100%;
}
.rta-rta textarea{
    background-color: #f0f0f0;
    padding: 8px 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: calc(100% - 28px);
    font-size: 0.9em;
    resize: none;
    height: 30vh;
}

#OpenCard-view #close-mod{
    width: 24px;
    position: absolute;
    top: -40px;
    right: -40px;
    cursor: pointer;
    opacity: 0.7;

}
#close-mod:hover{
    opacity: 0.9;
}

#close-mod img{
    width: 100%;
}
#new-rta{
    background-color: transparent;
    border-radius: 5px;
    padding: 8px 16px;
    color: slategray;
    border:1px dashed slategray;
    cursor: pointer;
    display: block;
    margin: 5% auto;
}
#new-rta:hover{
    background-color: #f0f0f0;
}

#add-rta{
    /* position: absolute; */
    top: 0%;
    left: 0;
    width: 100%;
    /* height: 100%; */
    background: #fff;
    display: flex;
    justify-content: center;
}
#add-rta>div{ width: 100%; }
#add-rta textarea, #OpenCard-view #add-rta input{
    display: block;
    width: 70%;
    padding: 3%;
    resize: none;
    border: 1px solid #ddd;
    background: #fafafa;
    border-radius: 7px;
}
#OpenCard-view #add-rta textarea{
    margin: auto;
    margin-bottom: 5%;
    height: 90px;
    color: slategray;

}
#OpenCard-view #add-rta input{
    margin: 10% auto 5%;
    color: slategray;
}
#OpenCard-view #add-rta>div button{
    margin: 3% auto;
    display: block;    
}
.cancel-newrta{
    display: block;
    margin: 5% auto;
    background: transparent;
    border: 1px solid #ddd;
    color: slategray;
    padding: 8px 25px;
    border-radius: 5px;
    cursor: pointer;
    width: 55%;
}
.cancel-newrta:hover{
    background: #f0f0f0;
}
.send-newrta{
    color: var(--violeta);
    background: #7f45c42e;
    border: var(--violeta) 1px solid;
    border-radius: 5px;
    padding: 8px 15px;
    margin: 5% auto;
    display: block;
    transition: .2s;
    width: 55%;
}
.send-newrta:hover{
    background: #7e45c469;
    font-weight: 500;
    cursor: pointer;
}

@media (max-width: 800px){
#close-mod{
    right: -13px;
    top: -13px;
}

#OpenCard-view>div{
    padding: 25px!important;
    width: calc(90% - 50px);
}
    

}


#Notifications-view{
    width: 200px!important;
    position: absolute!important;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 120%;
    z-index: 99;
    border-radius: 10px!important;
    padding: 17px 25px;
}
#Notifications-view li{
    font-size: 0.9em;
    color: #1c1c1c;
    text-align: center;
}
/* #ModalNewObj-view{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0,0,0,0.15);
    backdrop-filter: blur(5px);
    z-index: 99;
}

#ModalNewObj-view>div{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 60px;
    padding-bottom: 30px;
    background-color: #fff;
    border-radius: 10px;
    max-height: 70vh;
    overflow: auto;

} */
#ModalNewObj-view label{
    font-size: 0.8em;
    color: darkgray;
    display: block;
    margin-top: 5%;
}
#ModalNewObj-view textarea, #ModalNewObj-view input{
    /* margin: 3% 0; */
    width: 94%;
    padding: 3%;
    background-color: #fafafa;
    border: 1px solid #ddd;
    resize: none;
    border-radius: 5px;
}

#ModalNewObj-view input{
    padding: 1.5%;
    width: 97%;
    

}

#ModalNewObj-view>div>div{
    position: relative;
}


#new-rta{
    background-color: transparent;
    border-radius: 5px;
    padding: 8px 16px;
    color: slategray;
    border:1px dashed slategray;
    cursor: pointer;
    display: block;
    margin: 3% 0;
    width: 100%;
}
#new-rta:hover{
    background-color: #f0f0f0;
}

#add-rta{
    position: relative;
    width: 100%;
    /* height: 100%; */
    background: #fff;
    display: flex;
    justify-content: center;
    
}
#add-rta>div{ width: 100%; }
#add-rta textarea{
    height: 55px;
    color: slategray;
    margin: 0;
}
.deleteRta{
    width: 16px;
    position: absolute;
    text-align: start;
    top: 60%;
    left: 102%;
    -webkit-filter: invert(0.3);
            filter: invert(0.3);
    cursor: pointer;
}
.deleteRta img{
    width: 100%;
}

.deleteRta:hover{
    -webkit-filter: invert(0);
            filter: invert(0);
}
.cancel-newrta{
    display: block;
    margin: 0;
    background: transparent;
    border: 1px solid #ddd;
    color: slategray;
    padding: 8px 25px;
    border-radius: 5px;
    cursor: pointer;
}
.cancel-newrta:hover{
    background: #f0f0f0;
}
.send-newrta{
    color: var(--violeta);
    background: #7f45c42e;
    border: var(--violeta) 1px solid;
    border-radius: 5px;
    padding: 8px 15px;
    display: block;
    margin: 0;
    transition: .2s;
}
.send-newrta:hover{
    background: #7e45c469;
    font-weight: 500;
    cursor: pointer;
}
#new-obj-btns button{
    width: 45%;
}
#new-obj-btns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10%;
}

#ModalNewObj-view .close-mod{
    top: -36px;
    right: -36px;
}
@media (max-width: 800px){
#ModalNewObj-view .close-mod{
    right: -13px;
    top: -13px;
}

#ModalNewObj-view>div{
    padding: 25px!important;
    width: calc(90% - 50px);
}


}
    


.modal-container{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.15);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    z-index: 99;
}

.modal-container>div{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 60px;
    padding-bottom: 30px;
    background-color: #fff;
    border-radius: 10px;
    max-height: 70vh;
    overflow: auto;
    min-width: 50vw;
}


/* .close-mod{
    width: 24px;
    position: absolute;
    top: -40px;
    right: -40px;
    cursor: pointer;
    opacity: 0.7;
}
.close-mod:hover{
    opacity: 0.9;
}

.close-mod img{
    width: 100%;
} */
@media (max-width: 800px){
/* #close-mod{
    right: -13px;
    top: -13px;
} */

.modal-container>div{
    padding: 25px!important;
    width: calc(90% - 50px);
}


}
    

.close-mod{
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
}
#objecion-data h4{
    font-size: 1.3em;
    color: var(--violeta);
    margin-bottom: 5%;
}
.label-rtas{
    font-size: 0.8em;
    color: #1c1c1c;
}
.info-rta{
    margin: 3% 0;
    padding: 3% 0;
    border-bottom: 1px solid #ddd;
    position:relative
}
.info-rta>div{
    position:relative

}
.info-rta:nth-child(2){
    /* margin-top: 0!important;
    padding-top: 0!important; */
}
.info-rta:last-child{
    border: none;
}
.info-rta span{
    display: block;
    font-size: 0.7em;
    color: #1c1c1c;
    margin-top: 4%;
}
.nombre-rta, .contenido-rta, .campo-variaciones textarea, .inputs-rta{
    background-color: #f0f0f0;
    padding: 8px 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: calc(100% - 28px);
    font-size: 0.9em;
    resize: none;
}
.inputs-rta{
    width: auto;
}
textarea.contenido-rta{
    height: 15vh;
}
.delete-rta{
    width: 14px;
    position: absolute!important;
    text-align: 50%;
    top: 20px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 0;
    opacity: 0.7;
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 50px;
    padding: 2px 8px;
}
.delete-rta:hover{
    opacity: 1;
}
.delete-rta img{
    width: 100%;
}
#add-rta-btn{
    width: 100%;
    background-color: transparent;
    border: 1px dashed #a3a3a3;
    padding: 8px 0;
    border-radius: 5px;
    color: #3a3a3a;
    cursor: pointer;
    margin-bottom: 10%;
}
#add-rta-btn:hover{
    background-color: #d5d5d5;
}
#guardar-cambios-btn{
    background-color: var(--violeta);
    border-radius: 5px;
    border: var(--violeta);
    padding: 8px 22px;
    color: white;
    cursor: pointer;
    margin-right: 25px;

}
#descartar-cambios-btn{
    border: 1px solid #ddd;
    background: white;
    padding: 8px 22px;
    border-radius: 5px;
    cursor: pointer;
}
.campo-variaciones, .variac-tit{
    padding-left: 5%;
    position: relative;
}
.variac-tit{
    margin-bottom: 3%;
}
.campo-variaciones textarea{
    font-size: 0.8em;
}

.close-variac{
    position: absolute;
    right: -25px;
    width: 15px;
    top: 40%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);

}

.agregar-variante{
    position: absolute;
    text-align: start;
    top: 40px;
    right: -39px;
    border: 1px solid var(--violeta);
    color: var(--violeta);
    border-radius: 50px;
    padding: 0px 6px;
    cursor: pointer;
    font-size: 15px;
}
.agregar-variante:before{
    content: "Agregar variante";
    font-size: 0.8em;
    color: white;
    position:absolute;
    right: 115%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: var(--violeta);
    border-radius: 10px;
    padding: 5px 10px;
    display: none;
}
.agregar-variante:hover:before{
    display: block;
}
.close-mod{
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
}
#objecion-data h4{
    font-size: 1.3em;
    color: var(--violeta);
    margin-bottom: 5%;
}
.label-rtas{
    font-size: 0.8em;
    color: #1c1c1c;
}
.info-rta{
    margin: 3% 0;
    padding: 3% 0;
    border-bottom: 1px solid #ddd;
    position:relative
}
.info-rta>div{
    position:relative

}
.info-rta:nth-child(2){
    /* margin-top: 0!important;
    padding-top: 0!important; */
}
.info-rta:last-child{
    border: none;
}
.info-rta span{
    display: block;
    font-size: 0.7em;
    color: #1c1c1c;
    margin-top: 4%;
}
.nombre-rta, .contenido-rta, .campo-variaciones textarea, .inputs-rta{
    background-color: #f0f0f0;
    padding: 8px 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: calc(100% - 28px);
    font-size: 0.9em;
    resize: none;
}
.inputs-rta{
    width: auto;
}
textarea.contenido-rta{
    height: 15vh;
}
.delete-rta{
    width: 14px;
    position: absolute!important;
    text-align: 50%;
    top: 20px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 0;
    opacity: 0.7;
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 50px;
    padding: 2px 8px;
}
.delete-rta:hover{
    opacity: 1;
}
.delete-rta img{
    width: 100%;
}
#add-rta-btn{
    width: 100%;
    background-color: transparent;
    border: 1px dashed #a3a3a3;
    padding: 8px 0;
    border-radius: 5px;
    color: #3a3a3a;
    cursor: pointer;
    margin-bottom: 10%;
}
#add-rta-btn:hover{
    background-color: #d5d5d5;
}
#guardar-cambios-btn{
    background-color: var(--violeta);
    border-radius: 5px;
    border: var(--violeta);
    padding: 8px 22px;
    color: white;
    cursor: pointer;
    margin-right: 25px;

}
#descartar-cambios-btn{
    border: 1px solid #ddd;
    background: white;
    padding: 8px 22px;
    border-radius: 5px;
    cursor: pointer;
}
.campo-variaciones, .variac-tit{
    padding-left: 5%;
    position: relative;
}
.variac-tit{
    margin-bottom: 3%;
}
.campo-variaciones textarea{
    font-size: 0.8em;
}

.close-variac{
    position: absolute;
    right: -25px;
    width: 15px;
    top: 40%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);

}

.agregar-variante{
    position: absolute;
    text-align: start;
    top: 40px;
    right: -39px;
    border: 1px solid var(--violeta);
    color: var(--violeta);
    border-radius: 50px;
    padding: 0px 6px;
    cursor: pointer;
    font-size: 15px;
}
.agregar-variante:before{
    content: "Agregar variante";
    font-size: 0.8em;
    color: white;
    position:absolute;
    right: 115%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: var(--violeta);
    border-radius: 10px;
    padding: 5px 10px;
    display: none;
}
.agregar-variante:hover:before{
    display: block;
}
:root{
    --gris1: #979797;
    --gris2: #BBBECA;
    --gris3: #f0f0f0;
    --falso-negro: #272727;
    --blanco: #FFFFFF;
    --violeta: #7f45c4;
   }
  
  *{ margin: 0; padding: 0; }
  a{ text-decoration: none; color: inherit;}
  li{ list-style: none; }
  body{ overflow-x: hidden; color: #272727;font-family: 'Poppins', sans-serif; }
  h1, h2{  }
  h5{ }
  button, input, textarea{ font-family: 'Poppins', sans-serif }
  
  ::-webkit-scrollbar{width:6px;height:4px;position:absolute}
  ::-webkit-scrollbar-track{background:hsla(0,0%,78%,.8);-webkit-transition:background .2s;transition:background .2s}
  ::-webkit-scrollbar-track:hover{background:hsla(0,0%,78%,.5)}
  ::-webkit-scrollbar-thumb{background:hsla(0,0%,47%,.8);-webkit-transition:background .2s;transition:background .2s;-webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px)}
  ::-webkit-scrollbar-thumb:hover{background:hsla(0,0%,47%,.9)}
#Panel-view{
    background-color: #f0f0f0;
    min-height: 100vh;
}
#Panel-view>div{
    padding: 5% 0%;
}
#tab-selection>ul{
    display: flex;
    padding: 0 10%;
    border-bottom: 1px solid #ccc;

}
#tab-selection li{
    padding: 4px 3%;
    margin-right: 2%;
    font-weight: 300;
    color: #1c1c1c;
    cursor: pointer;
}
.selectedTab{
    font-weight: 500!important;
    border-bottom: 1px solid #1c1c1c;
}
#table-cont{
    padding: 3% 10%;
}




.table-config{
    margin-top: 5%;
    display:flex;
    justify-content: space-between;
}
.table-config>input{
    padding: 0 11px;
    min-width: 40%;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
}
#add-new-obj{
    border: var(--violeta) 1px solid;
    font-weight: 5000;
    border-radius: 4px;
    cursor: pointer;
    background: var(--violeta);
    color: #fff;
    padding: 8px 25px;
    font-size: 0.9em;
    transition: .2s;
}
#add-new-obj:hover{
    background: transparent;
    color: var(--violeta);

}


#NewObj form>div{
    margin: 4% 0;
}
#NewObj label{
    font-size: 0.9em;
    color: #7b7b7b;
    display: block;
    font-weight: 400;
    margin-bottom: 1%;
}
#NewObj textarea{
    resize: none;
    overflow: auto;
    background: transparent;
    border: none;
    border-bottom: 1px solid #777777;
    width: 94%;
    background: #e7e7e7;
    border-radius: 5px;
    height: 9vh;
    padding: 1% 2%;
}
#NewObj input{
    background: transparent;
    border: none;
    border-bottom: 1px solid #777777;
    background: #e7e7e7;
    border-radius: 5px;
    padding: 8px 15px;

}
#NewObj select{
    padding: 13px 31px;
    border: none;
    background: #e7e7e7;
    color: #1c1c1c;
    cursor: pointer;
}
:root {
    --select-border: #777;
    --select-focus: blue;
    --select-arrow: var(--violeta);
  }
#NewObj select option{
    padding: 5px 0;
    display: block;
}
#NewObj>div>form>button{
    padding: 4px 10px;
    border: var(--violeta) 1px solid;
    background: #7f45c457;
    color: var(--violeta);
    font-weight: 5000;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.85em;
    margin-top: 5%;
}
#NewObj>div>form>div>button{
    padding: 4px 10px;
    border: var(--violeta) 1px solid;
    background: #7f45c457;
    color: var(--violeta);
    font-weight: 5000;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}
.nueva-rta-btn{
    margin: 0!important;
    width: 100%!important;
    opacity: 0.7;
}
.nueva-rta-btn:hover{
    opacity: 0.9;
}
#create-obj{
    background: var(--violeta)!important;
    color: #fff!important;
    padding: 8px 25px!important;
    font-size: 1em;
    border: var(--violeta) 1px solid;
    transition: .2s;
    /* border-radius: 5px;
    cursor: pointer; */
}
#create-obj:hover{
    background: transparent!important;
    color: var(--violeta)!important;

}
#rene{
    border: #777 1px solid!important;
    background: transparent!important;
    color: #777!important;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;

    padding: 8px 25px!important;

    margin-left: 3.5%;

    font-size: 1em;
    transition: .2s;
}
#rene:hover{
    background-color: #d6d6d6!important;
}


#ObjTable-view .table{
    margin-top: 5%;
}
#ObjTable-view .tr>div{
    height: 9vh;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    padding: 0 2%;
}
#ObjTable-view .tr>div.opened{
    height: auto!important;
    padding: 2%;
}
#ObjTable-view .tr:nth-child(odd){
    background-color: #e7e7e7;
}
#ObjTable-view .th{
    padding: 0 1%;
    font-size: 0.9em;
    text-align: left;
    font-weight: 600;
}
#ObjTable-view .td{
    padding: 0 1%;
    /* border-right: 1px solid #fff;
    height: 100%; */
}
#ObjTable-view .td p{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 0.8em;
}
.id-cell{
    width: 3%;
}
.obj-cell{
    width: 50%;
}
.cant-cell{
    width: 5%;
}
.th.rtas-cell{
    height: auto!important;
}
.rtas-cell{
    width: 81%!important;
    font-size: 0.9em!important;
    height: 100%!important;
    display: flex;
    justify-content: flex-start!important;
    align-items: flex-start!important;
    flex-direction: column;
    position: relative;
    margin-top: 20px;
    margin-left: 5%;
}
.rtas-cell p{
    margin-bottom: 4%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.respuestas-desplegadas:hover{
    font-weight: 600;
}
.rtas-cell p:before{
    content: '\B7';
    font-weight: bolder;
    margin-right: 10px;
}
.key-cell{
    width: 15%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    overflow: hidden;
    align-items: center;
}
.key-cell p{
    display: inline-block!important;
    width: -webkit-fit-content!important;
    width: -moz-fit-content!important;
    width: fit-content!important;
    font-size: 0.65em!important;
    margin: 2px 2px;
    background-color: #d2b9f0;
    border-radius: 5px;
    padding: 2px;
}
.cat-cell{
    width: 8%;
}
.cant-cell p{
    text-align: center;
}
.arrowCerrada{
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
.arrowAbierta{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
.arrow-cont>img{
    width: 100%;
    position: absolute;
    transition: .2s;
    top: 38%;
}
.arrow-cont{
    width: 11px;
    height: 100%;
    position: relative;
    margin-right: 10px;
    margin-left: 20px;
    -webkit-filter: invert(0.2);
            filter: invert(0.2);
}
.delete-btn{
    position: absolute;
    top: 50%;
    left: 100%;
    padding: 2%;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    /* display: none; */
    font-size: 0.8em;
    font-weight: 600;
    opacity: 0;
}
#ObjTable-view .tr:hover .delete-btn{
    opacity: 0.7;
}
.delete-btn:hover{
    opacity: 1!important;
}
.row-opts{
    width: 16px;
    opacity: 0;
}
.tr:hover .row-opts{
    opacity: 0.8;
}
.row-opts:hover{
    opacity: 1!important;
}
.row-opts>img{
    width: 100%;
    -webkit-filter: invert(0.2);
            filter: invert(0.2);
}
.agregar-rta{
    position: absolute;
    right: -5%;
    top: 30%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    
}

.agregar-rta>button{
    background: var(--violeta);
    border:none;
    color: white;
    font-size: 12px;
    padding: 3px 7px;
    cursor: pointer;
    transition: .2s;
    border-radius: 50px;
}
.agregar-rta>button:hover{
    opacity: 0.8
}



#exist-cont>div{

}
#alert-info{
    border-radius: 3px;
    border: 1px solid rgb(255 214 66);
    background: rgb(255 235 162);
    padding: 5px 10px;
    font-size: 0.8em;
    color: #1c1c1c;
    /* display: flex;
    justify-content: space-between; */
    cursor: pointer;
}
#alert-info>div{
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
#alert-info>div img{
    margin-right: 10px;
}
#alert-info i{
    font-size: 0.9em;

}
#alert-detail button{
    padding: 4px 10px;
    border: var(--violeta) 1px solid;
    background: #7f45c457;
    color: var(--violeta);
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
}
#alert-detail>div{
    border-radius: 3px;
    padding: 12px 10px;
    background-color: rgb(255, 245, 208);
    border-bottom: 1px solid rgb(255 214 66);
}
#alert-detail>div>p{
    font-size: 0.8em;
    color: #1c1c1c;
    font-weight: 600;
}
#alert-detail>div>ul{
    display: flex;
}
#alert-detail>div>ul>li{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 0.65em;
    margin: 2px 2px;
    border: 1px solid #8d6db5;
    background-color: #d2b9f0;
    border-radius: 5px;
    padding: 2px;
}

.close-mod{
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
}

.ul-editable li{
    display: flex;
    align-items: center;
    margin: 2% 0;
}
.ul-editable li img{
    -webkit-filter: invert(0.4);
            filter: invert(0.4);
    margin-left: 10px;
    width: 16px;
    cursor: pointer;
}

#SugTable-view .table{
    margin-top: 5%;
}
#SugTable-view .tr>div{
    height: 9vh;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    padding: 0 2%;
}
#SugTable-view .tr:nth-child(odd){
    background-color: #e7e7e7;
}
#SugTable-view .th{
    padding: 0 1%;
    font-size: 0.9em;
    text-align: left;
    font-weight: 600;
}
#SugTable-view .td{
    padding: 0 1%;
    /* border-right: 1px solid #fff;
    height: 100%; */
}
#SugTable-view .td p{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 0.8em;
}
#SugTable-view .td p{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 0.8em;
}
#SugTable-view .sug-cell{
    width: 25%;
}
#SugTable-view .id-cell{
    width: 3%;
}
#SugTable-view .obj-cell{
    width: 50%;
}
#SugTable-view .cant-cell{
    width: 5%;
    text-align: center;
    font-size: 0.8em;
}
#SugTable-view .th.rtas-cell{
    height: auto!important;
}
#SugTable-view .tipo-cell{
    width: 10%;
    font-size: 0.8em;
}
#SugTable-view .rtas-cell{
    width: 52%!important;
    font-size: 0.9em!important;
    height: 100%!important;
    display: flex;
    justify-content: flex-start!important;
    align-items: flex-start!important;
    flex-direction: column;
    position: relative;
    width: 50%;
    margin-left: 5%!important;
    display: flex!important;
    flex-direction: column!important;
    justify-content: center!important;
}
#SugTable-view .rtas-cell p{
    margin-bottom: 4%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

}
#SugTable-view .rtas-cell p:before{
    content: '\B7';
    font-weight: bolder;
    margin-right: 10px;
}
#SugTable-view .key-cell{
    width: 15%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    overflow: hidden;
    align-items: center;
}
#SugTable-view .key-cell p{
    display: inline-block!important;
    width: -webkit-fit-content!important;
    width: -moz-fit-content!important;
    width: fit-content!important;
    font-size: 0.65em!important;
    margin: 2px 2px;
    background-color: #d2b9f0;
    border-radius: 5px;
    padding: 2px;
}
#SugTable-view .cat-cell{
    width: 8%;
}


#UsersTable-view .table{
    margin-top: 5%;
}
#UsersTable-view .tr>div{
    height: 9vh;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    padding: 0 2%;
}
#UsersTable-view .table-config>button{
    border: var(--violeta) 1px solid;
    border-radius: 4px;
    cursor: pointer;
    background: var(--violeta);
    color: #fff;
    padding: 8px 25px;
    font-size: 0.9em;
    transition: .2s;
    /* font-weight: 500; */
    /* letter-spacing: 2px; */
}
#UsersTable-view .tr:nth-child(odd){
    background-color: #e7e7e7;
}
#UsersTable-view .th{
    padding: 0 1%;
    font-size: 0.9em;
    text-align: left;
    font-weight: 600;
}

#UsersTable-view .td p{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 0.8em;
}
#UsersTable-view .td p{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 0.8em;
}


#UsersTable-view .sug-cell{
    width: 25%;
}
#UsersTable-view .id-cell{
    width: 3%;
}
#UsersTable-view .obj-cell{
    width: 50%;
}
#UsersTable-view .cant-cell{
    width: 5%;
    text-align: center;
    font-size: 0.8em;
}
#UsersTable-view .th.rtas-cell{
    height: auto!important;
}
#UsersTable-view .tipo-cell{
    width: 10%;
    font-size: 0.8em;
}
.user-name-cell{
    width: 25%;
}
.user-mail-cell{
    width: 40%;
}
.delete-cell{
    width: 10%;
    text-align: center;
}
.delete-user{
    font-size: 0.8em;
    color: #898989;
}
.tr:hover .delete-user{
    color: #585858;
}

.delete-user:hover{
    color:#2f2f2f;
    font-weight: 600
}
.save-user-change{
    padding: 8px 25px;
    border: var(--violeta) 1px solid;
    background: #7f45c457;
    color: var(--violeta);
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.85em;
    margin-top: 5%;
}
.discard-user-change{
    border: #777 1px solid!important;
    background: transparent!important;
    color: #777!important;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px 25px;
    margin-left: 3.5%;
    font-size: 0.85em;
    transition: .2s;
}
#UserModal>section{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.inputs-cont{
    width: 45%;
    display: inline-block;
    margin-bottom: 8%;
}
.inputs-cont label{
    font-size: 0.8em;
    display: block;
    color: #2f2f2f;

}
.inputs-cont input{
    background-color: #f0f0f0;
    border: none;
    border-bottom: 1px solid #585858;
    padding: 8px 12px;
    border-radius: 3px;
    width: 95%;

}
#modal-container-user{
    padding-bottom: 60px;
}
#Login-view{
    width: 100%;
    height: 100vh;
    background: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
}
#Login-view h4{
    font-size: 2em;
}
#Login-view label{
    display: block;
}
#Login-view input{
    display: block;
    margin: 7% auto;
    padding: 8px 24px;
    border-radius: 5px;
    border: 1px solid #ddd;
    background-attachment: #f0f0f0;
}
#Login-view button{
    
    padding: 10px 25px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
    margin: auto;
    display: block;
}

#Login-view button:hover{
    opacity: 0.8;
}
